import React, { useEffect, useState } from 'react'

import { Link as RouterLink } from 'react-router-dom'

import { 
    Paper, Container, CircularProgress, Box, Typography, Table, 
    TableBody, TableHead, TableCell, TableRow, TableContainer, Button
} from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import Page from 'components/Page';
import AddObservation from 'components/AddObservation';
import DetectionListItem from 'components/DetectionListItem';

import { AuthManager, DetectionManager } from 'models'


interface PageProps {
    self?: AuthManager.User | null
}

export const OverviewPage: React.FC<PageProps> = ({ self }) => {

    const [unseenDetections, setUnseenDetections] = useState<DetectionManager.Detection[]>()

    const [stats, setStats] = useState<DetectionManager.Stats>()

    const get_detections = async () => {
        setUnseenDetections(undefined)
        DetectionManager.get_unseen_detections().then((detections) => {
            setUnseenDetections(detections)
        });
        setStats(undefined)
        DetectionManager.get_stats().then((stats) => {
            setStats(stats)
        });

    }
    

    useEffect(() => {
        get_detections()
    }, [])

    return <Page self={self}>
        <Container>
            <Paper sx={{px: 1, py: 2, mt: 1}}>
                <AddObservation onAdd={() => {
                    get_detections()
                }} />
            </Paper>

            <Paper>
                {!stats && <Box sx={{display: 'grid', placeItems: 'center'}}><CircularProgress sx={{m:1}} size={20} /></Box>}
                {stats && <Box sx={{m: 1}}>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow><TableCell>
                                         Stats
                                    </TableCell>
                                    <TableCell>
                                        <b>Observations</b>
                                    </TableCell>
                                    <TableCell>
                                        <b>Detections</b>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell>
                                        <b>Today</b>
                                    </TableCell>
                                    <TableCell>
                                        {stats.observations_today}
                                    </TableCell>
                                    <TableCell>
                                        {stats.detections_today}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <b>Total</b>
                                    </TableCell>
                                    <TableCell>
                                        {stats.observations_total}
                                    </TableCell>
                                    <TableCell>
                                        {stats.detections_total}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    
                    </Box>}
            </Paper>
            

            <Paper sx={{mb: 3}}>
                <Box sx={{m: 2}}>
                <Typography variant="h6">
                    New Alerts
                </Typography>
                <Typography variant="subtitle1" color="text.secondary">
                    A list of your unseen detections
                </Typography>
                </Box>
            {unseenDetections == null && <Box sx={{display: 'grid', placeItems: 'center'}}><CircularProgress sx={{m:1}} size={20} /></Box>}
            {unseenDetections && unseenDetections.map(
                (detection) => <DetectionListItem key={detection.id} dense detection={detection}  />)
            }

            <Button sx={{width: '100%'}} component={RouterLink} to='/detections'> View All Detections <ChevronRightIcon /></Button>
            </Paper>
            
        </Container>
    </Page>
}

OverviewPage.displayName = 'OverviewPage'
export default OverviewPage