import React from 'react'

import {  Link as RouterLink  } from 'react-router-dom'

import { Box, Typography, Button } from '@mui/material';

import Page from 'components/Page';

export const Error404Page: React.FC = () => {


    return <Page>
        <Box>
            <Typography align="center" variant="h5">Error 404</Typography>
            <Typography align="center" variant="h6" color="text.secondary">Page Not Found</Typography>

            <Button component={RouterLink} to='/'>Return Home</Button>
        </Box> 
    </Page>
}

Error404Page.displayName = 'Error404Page'
export default Error404Page