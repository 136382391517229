import React from 'react';

import { Link as RouterLink, } from 'react-router-dom'

import { AuthManager } from 'models';

import { Box, Toolbar, AppBar, Button, Link } from '@mui/material';

interface NavProps {
    self?: AuthManager.User | null
}

const Page: React.FC<NavProps> = ({self}) => {


    return (
        <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" sx={{backgroundColor: '#fff', color: '#444'}}>
        <Toolbar>

        <Link component={RouterLink} to="/">
            <img
                aria-label="Navigate to menu"
                src="/logo-name.png"
                style={{
                    width: 150,
                    height: 48,
                }}
                alt=""
            />
        </Link>
        
          { self && <Button color="inherit" onClick={() => {
              AuthManager.logout();
          }} sx={{ml: 'auto'}}>Logout</Button>}
          { !self && <Button color="inherit" component={RouterLink} to='/overview' sx={{ml: 'auto'}}>Login</Button>}
        </Toolbar>
      </AppBar>
    </Box>
    );
};

Page.displayName = 'Page';
export default Page;
