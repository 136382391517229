import { CLIENT_ID, OAUTH_URL, API_URL, LOCALSTORAGE } from 'config';
import type { ErrorResp } from 'models';
import { NavigateFunction } from 'react-router-dom';

/**
 * The standard OAuth2 Credentials Payload
 */
export interface OAuth2CredsRaw {
    access_token: string;
    expires_in: number;
    token_type: string;
    scope: string;
    refresh_token?: string;
}

export interface OAuth2Creds {
    accessToken: string;
    expiresIn: number;
    tokenType: string;
    scopes: string[];
    refreshToken?: string;
    refreshTime?: Date;
    accessTokenExpire: Date;
}

export interface State {
    path: string;
}


export interface UserRaw {
    id: number;
    username: string;
    email: string;
    groups: Group[];
    first_name: string;
    last_name: string;
    is_active: boolean;
    is_staff: boolean;
    is_superuser: boolean;
}

export interface Group {
    id: number;
    name: string;
}

export interface User {
    id: number;
    fullName: string;
    username: string;
    firstName: string;
    lastName: string;
    email: string;
    groups: Group[];
    isActive: boolean;
    isStaff: boolean;
    isSuperuser: boolean;
}

export interface Cache {
    authCode?: string | null;
    creds?: OAuth2Creds | null;
    user?: User | null;
}

const getAuthFromLocalStorage = (): OAuth2Creds | null => {
    const lsCreds = localStorage.getItem(LOCALSTORAGE.AUTH);

    if(!lsCreds) return null;

    const creds = JSON.parse(lsCreds);

    return {
        ...creds,
        refreshTime: creds.refreshTime ? new Date(creds.refreshTime) : undefined,
        accessTokenExpire: new Date(creds.accessTokenExpire),
    }
}

export const cache: Cache = {
    creds: getAuthFromLocalStorage(),
}

const TOKEN_ROUTE = `${OAUTH_URL}token/`;
const AUTHORISATION_ROUTE = `${OAUTH_URL}authorize/`;
const LOGOUT_ROUTE = `${API_URL}user/logout/`;
const SELF_ROUTE = `${API_URL}user/self/`;

const rawOAuthCredsToInternal = (raw: OAuth2CredsRaw): OAuth2Creds => ({
    accessToken: raw.access_token,
    expiresIn: raw.expires_in,
    scopes: raw.scope.split(' '),
    tokenType: raw.token_type,
    refreshToken: raw.refresh_token,
    refreshTime: raw.refresh_token
        ? new Date(Date.now() + 86_400_000)
        : undefined,
    accessTokenExpire: new Date(Date.now() + raw.expires_in * 1000),
});

const rawUserToInternal = ({first_name, last_name, is_active, is_staff, is_superuser, ...rest}: UserRaw): User => ({
    ...rest,
    firstName: first_name,
    lastName: last_name,
    fullName: `${first_name} ${last_name}`,
    isActive: is_active,
    isStaff: is_staff,
    isSuperuser: is_superuser,
});

export const getAuthHeaders = () => {
    if(!cache.creds) return new Headers();

    const headers: HeadersInit = new Headers();

    headers.set(
        'Authorization',
        `${cache.creds.tokenType} ${cache.creds.accessToken}`,
    );

    return headers;
}

export const getSelf = async () => {
    if(!cache.creds) return null;

    const headers: HeadersInit = new Headers();

    headers.set(
        'Authorization',
        `${cache.creds.tokenType} ${cache.creds.accessToken}`,
    );

    const resp = await fetch(`${SELF_ROUTE}`, {
        headers,
    })

    if(!resp.ok) return null;

    return rawUserToInternal(await resp.json());
}

export const redirectToAuth = async () => {
    const params = new URLSearchParams({
        client_id: CLIENT_ID,
        response_type: 'code',
        redirect_uri: `${window.location.origin}/redirect`,
        state: JSON.stringify({
            path: `${window.location.pathname}${window.location.search}`,
        }),
        locale: 'en-au',
    });
    window.location.href = `${AUTHORISATION_ROUTE}?${params.toString()}`;
};

export const loadToken = async ({
    authCode,
    tokens,
}: {
    authCode?: string;
    tokens?: OAuth2Creds | null;
}): Promise<OAuth2Creds | ErrorResp> => {
    const params = authCode
        ? new URLSearchParams({
              client_id: CLIENT_ID,
              grant_type: 'authorization_code',
              redirect_uri: `${window.location.origin}/redirect`,
              code: authCode,
          })
        : new URLSearchParams({
              client_id: CLIENT_ID,
              grant_type: 'refresh_token',
              refresh_token: tokens?.refreshToken ?? '',
          });

    const resp = await fetch(TOKEN_ROUTE, {
        method: 'POST',
        body: params,
    });

    if (!resp.ok) {
        return {
            error: resp.statusText,
            code: resp.status,
        };
    }

    const rawCreds = (await resp.json()) as OAuth2CredsRaw;

    cache.creds = rawOAuthCredsToInternal(rawCreds);

    if(cache.creds)
        localStorage.setItem(LOCALSTORAGE.AUTH, JSON.stringify(cache.creds))

    return cache.creds;
};

export const isLoggedIn = () => {
    return !!cache.creds;
}

export const getToken = async () => {
    if(cache.creds && cache.creds.accessTokenExpire > new Date()) {
        return cache.creds;
        
    }


    await loadToken({
        tokens: cache.creds,
    })
    
}

export const handleAuthCode = async (navigate: NavigateFunction): Promise<void> => {
    const urlParams = new URLSearchParams(window.location.search);
    
    const code = urlParams.get('code');
    const redirectPath = urlParams.get('state');
  
    if (code) {
        cache.authCode = code;

        await loadToken({
            authCode: code,
        })

        if (window.history.pushState) {
            if (redirectPath) {
                const path = JSON.parse(redirectPath)?.path;
                navigate(path ?? window.location.origin);
            } else {
                window.history.pushState(
                    { path: window.location.origin },
                    '',
                    window.location.origin,
                );
            }
        }
    } else {
        navigate('/');
    }
};

export const getUser = async () => {
    if(cache.creds && cache.user == null) return await getSelf();
    return cache.user
}

export const logout = async (): Promise<void> => {
    localStorage.removeItem(LOCALSTORAGE.AUTH);

    const params = new URLSearchParams({
        url: window.location.origin,
    });
    window.location.href = `${LOGOUT_ROUTE}?${params.toString()}`;
};
