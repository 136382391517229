import { createTheme } from '@mui/material/styles';


export const theme = createTheme({
    palette: {
        primary: {
            main: '#637a36'
        },
        secondary: {
            main: '#ff964c'
        }
    }
});

export default theme;