import React, { useState, useEffect } from 'react'

import { Link as RouterLink } from 'react-router-dom'

import { 
    Paper, CircularProgress, Box, Typography, Button
} from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'; 

import Page from 'components/Page';

import { AuthManager, DetectionManager } from 'models'

import DetectionListItem from 'components/DetectionListItem';

interface PageProps {
    self?: AuthManager.User | null
}

export const ListDetectionsPage: React.FC<PageProps> = ({self}) => {

    const [unseenDetections, setUnseenDetections] = useState<DetectionManager.Detection[]>()

    const get_detections = async () => {
        setUnseenDetections(undefined)
        DetectionManager.get_detections().then((detections) => {
            setUnseenDetections(detections)
        });

    }

    useEffect(() => {
        get_detections()
    }, [])

    return (
    <Page self={self}>
        <Paper>
            <Button component={RouterLink} to='/overview'><ChevronLeftIcon /> Go Back</Button>
            <Box sx={{m: 2}}>
            <Typography variant="h6">
                Detections
            </Typography>
            <Typography variant="subtitle1" color="text.secondary">
                A list of all detections
            </Typography>
            </Box>

        <Box sx={{display: 'grid', gap: 1}}>
        {unseenDetections == null && <Box sx={{display: 'grid', placeItems: 'center'}}><CircularProgress sx={{m:1}} size={20} /></Box>}
        {unseenDetections && unseenDetections.map(
            (detection) => <DetectionListItem key={detection.id} detection={detection}  />)
        }
        </Box>
        </Paper>
    </Page>
    )
}

ListDetectionsPage.displayName = 'ListDetectionsPage'
export default ListDetectionsPage