import { API_URL } from "config";
import { AuthManager, ObservationManager } from "models";

export interface IssueType {
    id: number;
    name: string;
    group: string;
    description: string;
    mitigation_techniques: string;
    severity: number;
    image: string;
    created: string;
    affects_vegetation: string[];
}

export interface Detection {
    id: number;
    observation: ObservationManager.Observation;
    issue_type: IssueType;
    detected: string;
    severity: number;
    seen: boolean;
}

export interface Stats {
    observations_total: number;
    observations_today: number;
    detections_total: number;
    detections_today: number;
}



export const mark_detection_seen = async (detection_id: number, seen = true) => {

    const url = `${API_URL}detection/${detection_id}/`

    const formData = new FormData()

    formData.append('seen', `${seen}`)

    const headers = AuthManager.getAuthHeaders()

    headers.set('content-type', 'application/json')

    const resp = await fetch(url, {
        method: 'PATCH',
        
        body: JSON.stringify({
            seen,
        }),
        headers,
    })

    return {
        'success': resp.ok
    }
}

const GET_DETECTIONS_URL = `${API_URL}detection/`;

export const get_detections = async () => {

    const resp = await fetch(`${GET_DETECTIONS_URL}?ordering=-detected`, {
        method: 'GET',
        headers: AuthManager.getAuthHeaders(),
    })

    const data = await resp.json();

    return data.results as Detection[];
}

export const get_detection = async (id: number) => {

    const resp = await fetch(`${GET_DETECTIONS_URL}${id}/`, {
        method: 'GET',
        headers: AuthManager.getAuthHeaders(),
    })

    const data = await resp.json();

    return data as Detection;
}

const GET_UNSEEN_DETECTIONS_URL = `${API_URL}detection/?seen=false&ordering=-severity`;

export const get_unseen_detections = async () => {


    const resp = await fetch(GET_UNSEEN_DETECTIONS_URL, {
        method: 'GET',
        headers: AuthManager.getAuthHeaders(),
    })

    const data = await resp.json();

    return data.results as Detection[];
}


const GET_STATS_URL = `${API_URL}detection/stats/`;
export const get_stats = async () => {


    const resp = await fetch(GET_STATS_URL, {
        method: 'GET',
        headers: AuthManager.getAuthHeaders(),
    })

    const data = await resp.json();

    return data as Stats;
}
