import React from 'react';

import { Box } from '@mui/material';

import Navbar from 'components/Navbar';

import { AuthManager } from 'models'

interface PageProps {
    style?: React.CSSProperties;
    className?: string;
    children: React.ReactElement;
    self?: AuthManager.User | null;
}

const Page: React.FC<PageProps> = ({
    style,
    className,
    children,
    self,
}) => {

    return (
        <Box>
            <Navbar self={self} />
            <Box
                className={className}
                style={style}
            >
                {children}
            </Box>
        </Box>
    );
};

Page.displayName = 'Page';
export default Page;
