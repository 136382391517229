import React from 'react'

import { useNavigate } from 'react-router-dom'

import { Card, CardActions, CardContent, CardHeader,CardMedia, Avatar, IconButton, Typography, Button  } from '@mui/material'
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { DetectionManager } from 'models'

import { toTimeDDMonYearFormat, severityToColor } from 'utils';


interface Props {
    detection: DetectionManager.Detection;
    dense?: boolean;
}

export const ListDetectionsPage: React.FC<Props> = ({detection, dense}) => {

    const navigate = useNavigate();

    const { severity, issue_type, observation } = detection;

    const detected = new Date(detection.detected)

    return (<Card>
        <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: severityToColor(severity) }} aria-label="severity">
            {severity}
          </Avatar>
        }
        action={
            dense ?
          <IconButton aria-label="mark as read" onClick={() => {
            navigate(`/detection/${detection.id}`)
          }} >
            <ChevronRightIcon />
          </IconButton> : undefined
        }
        title={`${issue_type.name} Detected`}
        subheader={toTimeDDMonYearFormat(detected) }
      />
      {!dense && <CardMedia
        component="img"
        height="194"
        image={observation.image}
        alt={observation.vegetation.name}
      />}
      {!dense && <CardContent>
        <Typography variant="body2" color="text.secondary">
          Detected {issue_type.name} in your {observation.vegetation.name}.
        </Typography>
      </CardContent>}
      {!dense && <CardActions>
          <Button sx={{ml: 'auto'}} onClick={() => {
              navigate(`/detection/${detection.id}`)
          }}>
              View Details
          </Button>
      </CardActions>}
    </Card>)
}

ListDetectionsPage.displayName = 'ListDetectionsPage'
export default ListDetectionsPage