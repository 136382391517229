/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';

import {  useNavigate } from 'react-router-dom'

import { Box, } from '@mui/material';

import Navbar from 'components/Navbar'
import { AuthManager } from 'models';


export function RedirectPage() {

  const navigate = useNavigate();

  useEffect(() => {
      AuthManager.handleAuthCode(navigate)
  }, [])

  return (
    <Box>
      <Navbar />
    </Box>
  );
}

export default RedirectPage;