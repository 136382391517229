import React, { useEffect, useState } from 'react';

import { useNavigate, useLocation } from 'react-router-dom';

import {
  Routes,
  Route,
} from "react-router-dom";

import { AuthManager } from 'models';


import Error404Page from 'pages/Error404Page';
import OverviewPage from 'pages/OverviewPage';
import ListDetectionsPage from 'pages/ListDetectionsPage';
import DetectionDetailsPage from 'pages/DetectionDetailsPage';

const REFRESH_DELAY = 25 * 60_000;

const AUTH_ROUTES = [
  '/overview',
  '/detections',
]

function AuthenticatedApp() {

  const navigate = useNavigate();
  const location = useLocation();

  const [self, setSelf] = useState<AuthManager.User>();

  useEffect(() => {
      AuthManager.getToken().then(() => AuthManager.getSelf()).then((s) => {
        setSelf(s ?? undefined)
      })

      const interval = setInterval(() => {
          AuthManager.getToken()
      }, REFRESH_DELAY);
      return () => {
          clearInterval(interval);
      };
    }, [navigate]);

  useEffect(() => {
    if(AUTH_ROUTES.find((x) => x.startsWith(location.pathname)) && !AuthManager.cache.creds) {
      AuthManager.redirectToAuth();
    }
  }, [location.pathname, self])

  return (
    <Routes>
      {self && <>
        <Route path="/overview" element={<OverviewPage self={self} />} />
        <Route path="/detections" element={<ListDetectionsPage self={self}  />} />
        <Route path="/detection/:dectection_id_str" element={<DetectionDetailsPage self={self}  />} />
        </>
      }
      <Route element={<Error404Page />} />
    </Routes>
  );
}

export default AuthenticatedApp;
