import React from 'react';


import {
  Routes,
  Route,
} from "react-router-dom";


import LandingPage from 'pages/LandingPage';
import RedirectPage from 'pages/RedirectPage';
import Error404Page from 'pages/Error404Page';
import AuthenticatedApp from 'AuthenticatedApp';

function App() {

  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="/redirect" element={<RedirectPage />} />
      <Route path="/*" element={<AuthenticatedApp />} />
      <Route element={<Error404Page />} />
    </Routes>
  );
}

export default App;
