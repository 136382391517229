import React from 'react';

import { Link as RouterLink } from 'react-router-dom'

import { Container, Box, Typography, Button, Grid, IconButton } from '@mui/material';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

import Navbar from 'components/Navbar'


export function LandingPage() {
  return (
    <Box>
      <Navbar />
      <Container maxWidth="lg" sx={{mt: 1}}>
        
        {/* Body */}
        <Box sx={{
            bgcolor: 'background.paper',
            pt: 8,
            pb: 6,
          }}>
              <Container maxWidth="sm">

              <img
                aria-label="Navigate to menu"
                src="logo-name.png"
                style={{
                    width: '90%'
                }}
                alt=""
            />
                <Typography
                    component="h2"
                    variant="h3"
                    align="center"
                    color="text.primary"
                    gutterBottom
                    >
                Coming Soon!
                </Typography>
                <Typography variant="h6" align="center" color="text.secondary" paragraph>
                    AgriAutomation helps you detect diseases and pests amongst your crops early using 
                    low cost agri-robotics to automatically monitor your crops. Get early warning of 
                    crop-killing diseases and remotely check in on your crops status via our web portal
                </Typography>

                <Box sx={{display: 'grid', placeItems: 'center', mb: 4}}>
                  <Typography variant="body1" align="center" color="text.secondary" paragraph>
                    Click the button below to login
                  </Typography>
                  <Button variant="contained" component={RouterLink} to='/overview'>View Portal</Button>
                </Box>

                <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                    <Box sx={{display: 'grid', placeItems: 'center', mb: 2}}>
                      <img src="/drone.png" alt="automation" style={{width: 'min(80%, 256px)'}} />
                    </Box>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    AgriAutomation aims to lower the barrier of entry to high efficiency farming by 
                    developing open-source agri-robotics (and associated support systems), allowing 
                    small scale farmers access to technology that is otherwise reserved for larger 
                    and more capital intensive farms.
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                  <Box sx={{display: 'grid', placeItems: 'center', mb: 2}}>
                    <img src="/chip.png" alt="ai" style={{width: 'min(80%, 256px)'}} />
                  </Box>
                    
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    AgriAutomation can automatically detect various issues around your farm. It's 
                    state-of-the-art algorithms will act as an early warning system and give you peace 
                    of mind
                  </Grid>
                  
                </Grid>

                <Typography
                    variant="h4"
                    align="center"
                    color="text.primary"
                    gutterBottom
                    sx={{my: 4}}
                    >
                  Who are we?
                </Typography>

                <Grid container spacing={2} sx={{mb: 4}}>
                  <Grid item xs={12} lg={6}>
                        <Box sx={{display: 'grid', placeItems: 'center', mb: 2}}>
                          <img src="/michael.jpg" alt="Michael Bauer" style={{height: 192, borderRadius: 300}}  />
                        </Box>
                        <Typography
                            variant="h6"
                            align="center"
                            color="text.primary"
                            gutterBottom
                            >
                          Michael Bauer
                        </Typography>
                        <Box sx={{display: 'grid', placeItems: 'center', mb: 2}}>
                          <IconButton component="a" href="https://www.linkedin.com/in/michael-bauer-engineer/" target="_blank">
                            <LinkedInIcon sx={{width: 32, height: 32}} />
                          </IconButton>
                        </Box>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                        <Box sx={{display: 'grid', placeItems: 'center', mb: 2}}>
                          <img src="/matt.jpg" alt="Matt Cabanag" style={{height: 192, borderRadius: 300}}  />
                        </Box>
                        <Typography
                            variant="h6"
                            align="center"
                            color="text.primary"
                            gutterBottom
                            >
                          Matt Cabanag
                        </Typography>
                        <Box sx={{display: 'grid', placeItems: 'center', mb: 2}}>
                          <IconButton component="a" href="https://www.linkedin.com/in/mattavc/" target="_blank">
                            <LinkedInIcon sx={{width: 32, height: 32}} />
                          </IconButton>
                        </Box>
                  </Grid>
                </Grid>

                <Typography
                    variant="h4"
                    align="center"
                    color="text.primary"
                    gutterBottom
                    >
                  Get in touch!
                </Typography>
                <Typography
                  variant="h6"
                  align="center"
                  color="text.secondary"
                  gutterBottom
                  >
                  hello@agriautomations.com
                </Typography>

                
              </Container>
        </Box>
    </Container>
    </Box>
  );
}

export default LandingPage;