import { yellow, orange, red } from '@mui/material/colors';

export const severityToColor = (severity: number) => {
    if(severity <= 1) {
        return yellow[500]
    } else if(severity === 2) {
        return orange[500]
    } else {
        return red[500]
    }
}

/**
 *
 * @param age The age in millisecionds. Negative age returns useful text, but not supported.
 */
 export const toReadableAge = (
    age: number | undefined | null,
): string | null => {
    if (age == null) return null;
    if (age < 0) return "hasn't occured yet";
    if (age < 1000) return 'now';
    let time = Math.round(age / 1000);
    if (age < 60 * 1000) return `${time} second${time === 1 ? '' : 's'} ago`;
    time = Math.round(age / (1000 * 60));
    if (age < 60 * 60 * 1000)
        return `${time} minute${time === 1 ? '' : 's'} ago`;
    time = Math.round(age / (1000 * 60 * 60));
    if (age < 24 * 60 * 60 * 1000)
        return `${time} hour${time === 1 ? '' : 's'} ago`;
    time = Math.round(age / (1000 * 60 * 60 * 24));
    if (age < 7 * 24 * 60 * 60 * 1000)
        return `${time} day${time === 1 ? '' : 's'} ago`;
    time = Math.round(age / (1000 * 60 * 60 * 24 * 7));
    return `${time} week${time === 1 ? '' : 's'} ago`;
};

const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
];

export const toDDMonFormat = (date: Date) => {
    return `${date.getDate()} ${months[date.getMonth()]}`;
};

export const toDDMMYYYYFormat = (date: Date) => {
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
};

export const toTimeDDMonYearFormat = (date: Date) => {
    return `${date.getHours() < 10 ? '0' : ''}${date.getHours()}:${
        date.getMinutes() < 10 ? '0' : ''
    }${date.getMinutes()} ${date.getDate()} ${
        months[date.getMonth()]
    } ${date.getFullYear()}`;
};

export const isToday = (date: Date) =>
    new Date().toDateString() === date.toDateString();
