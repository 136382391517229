import { API_URL } from "config";
import { AuthManager } from "models";

export interface Vegetation {
    id: number;
    name: string;
    scientific_name: string;
    description: string;
    image: string;
    created: string;
}

export interface Observation {
    id: number;
    image: string;
    status: string;
    created: string;
    longitude: number | null;
    latitude: number | null;
    owner: number;
    vegetation: Vegetation;
}


export interface NewObservation {
    image: File;
    dataUrl: string;
    vegetation: number;
    longitude?: number;
    latitude?: number;
}

const getMimeFromDataURL = (url: string) => url.substring(url.indexOf(':') + 1, url.indexOf(';'));

const mimeToExtension: Record<string, string> = {
  'image/png': 'png',
  'image/jpeg': 'jpg',
};


const UPLOAD_OBSERVATION_URL = `${API_URL}observation/upload/`

export const upload_observation = async (obs: NewObservation) => {

    const formData  = new FormData();

    const fileName = obs.image
      ? `observation-${new Date().getTime()}.${mimeToExtension[getMimeFromDataURL(obs.dataUrl)]}`
      : '';

    if (obs.image) formData.append('photo', obs.image, fileName);

    if(obs.vegetation) formData.append('vegetation', `${obs.vegetation}`);

    if(obs.longitude) formData.append('longitude', `${obs.longitude}`);
    if(obs.latitude) formData.append('latitude', `${obs.latitude}`);

    const resp = await fetch(UPLOAD_OBSERVATION_URL, {
        method: 'POST',
        body: formData,
        headers: AuthManager.getAuthHeaders(),
    })

    return {
        'success': resp.ok
    }
}

const GET_VEGETATION_LIST_URL = `${API_URL}vegetation/`;

export const get_vegetation_list = async () => {


    const resp = await fetch(GET_VEGETATION_LIST_URL, {
        method: 'GET',
        headers: AuthManager.getAuthHeaders(),
    })

    const data = await resp.json();

    return data.results as Vegetation[];
}