import React, { useState, useEffect } from 'react';

import { Box, Autocomplete, TextField, Button, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab'

import { ObservationManager } from 'models';

interface AddObservationProps {
    onAdd?: () => void;
}


const getUserLocation = async () => {
    return new Promise<GeolocationPosition | null>((resolve) => {
        navigator.geolocation.getCurrentPosition((position) => {
            resolve(position)
        }, () => {
            console.log('Unable to get users location')
            resolve(null)
        })
    })
}

const AddObservation: React.FC<AddObservationProps> = ({ onAdd }) => {

    const [vegetationList, setVegetationList] = useState<ObservationManager.Vegetation[]>();

    const [selectedVegetation, setSelectedVegetation] = useState<ObservationManager.Vegetation | null>(null);

    const [imageURL, setImageURL] = useState<string>()
    const [image, setImage] = useState<File>()

    const [uploading, setUploading] = useState(false);


    const uploadObservation = () => {
        if(!imageURL) return;
        if(!image) return;
        if(!selectedVegetation) return;

        getUserLocation().then((position) => ObservationManager.upload_observation({
            image: image,
            dataUrl: imageURL,
            vegetation: selectedVegetation.id,
            longitude: position?.coords.longitude,
            latitude: position?.coords.latitude,
        })).then(() => {
            setUploading(false);
            setImageURL(undefined);
            setImage(undefined);
            if(onAdd) onAdd();
        })

        setUploading(true);
    }

    useEffect(() => {
        if(!vegetationList) {
            ObservationManager.get_vegetation_list().then((data) => {
                setVegetationList(data)
            })
        }
    })

    return (
        <Box sx={{
            mx: 1,
            display: 'grid',
            gap: 1,
        }}>
            <Typography variant="h6">Make an Observation</Typography>
            <Typography variant="subtitle2" color="text.secondary">Select a Vegetation type, then add a photo.</Typography>
            <Autocomplete
                value={selectedVegetation}
                onChange={(event: any, newValue: ObservationManager.Vegetation | null) => {
                    setSelectedVegetation(newValue);
                }}
                placeholder="Select Vegetation"
                fullWidth
                getOptionLabel={(option) => option.name}
                options={vegetationList ?? []}
                renderInput={(params) => <TextField label="Select Vegetation" {...params} />}
                disabled={uploading}
            />
            {imageURL && <img src={imageURL} alt="Selected" style={{width: '100%'}} />}
            <Button
                variant="contained"
                component="label"
                disabled={uploading}
                >
                Select Image
                <input
                    type="file"
                    hidden
                    onChange={(event) => {
                        if (event.target.files && event.target.files[0]) {
                            const img = event.target.files[0];
                            setImageURL(URL.createObjectURL(img))
                            setImage(img);
                        }
                        // @ts-ignore
                        event.target.value = null;
                    }}
                />
            </Button>
                
            <LoadingButton 
                variant="contained" 
                onClick={uploadObservation} 
                disabled={selectedVegetation == null || imageURL == null}
                loading={uploading}
            >
                Upload Observation
            </LoadingButton>
        </Box>
    );
};

AddObservation.displayName = 'AddObservation';
export default AddObservation;
