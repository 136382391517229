import React, {useEffect, useState } from 'react'
import { useParams, Link as RouterLink  } from 'react-router-dom'

import { 
    Card,  CardContent, CardHeader,CardMedia, Avatar, Box, Typography,
    Button, Container, CircularProgress
} from '@mui/material'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'; 

import Page from 'components/Page';

import { AuthManager, DetectionManager } from 'models'
import { toTimeDDMonYearFormat, severityToColor } from 'utils';


interface PageProps {
    self?: AuthManager.User | null
}

export const DetectionDetailsPage: React.FC<PageProps> = ({self}) => {
    const { dectection_id_str } = useParams()

    const detection_id = dectection_id_str != null ? +dectection_id_str : null;

    const [detection, setDetection] = useState<DetectionManager.Detection>()

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if(!detection && detection_id != null) DetectionManager.get_detection(detection_id).then((d) => {
            setDetection(d);
            setLoading(false);
        });
        setLoading(true);
    }, [detection, detection_id])

    useEffect(() => {
        if(detection && !detection.seen)
            DetectionManager.mark_detection_seen(detection.id);
    }, [detection])


    if(!detection ) {
        if(loading) {
            return <Page self={self}>
            <Box sx={{display: 'grid', placeItems: 'center',}}>
                <CircularProgress sx={{m: 1}} size={20} />
            </Box> 
        </Page>
        } else {
            return <Page self={self}>
            <Box>
                <Typography align="center" variant="h5">Error 404</Typography>
                <Typography align="center" variant="h6" color="text.secondary">Detection Not Found</Typography>
    
                <Button component={RouterLink} to='/overview'>Return Home</Button>
            </Box> 
        </Page>
        }
        
    }

    const { severity, issue_type, observation } = detection;

    const { vegetation } = observation;

    const detected = new Date(detection.detected)

    return (
    <Page self={self}>
        <Container  maxWidth="lg" sx={{mt: 1}}>
            <Button component={RouterLink} to='/detections'><ChevronLeftIcon /> Go Back</Button>
            <Card sx={{my: 1}}>
                <CardHeader
                    avatar={
                    <Avatar sx={{ bgcolor: severityToColor(severity) }} aria-label="severity">
                        {severity}
                    </Avatar>
                    }
                    title={`${issue_type.name} Detected`}
                    subheader={toTimeDDMonYearFormat(detected) }
                />
                <CardMedia
                    component="img"
                    image={observation.image}
                    alt={vegetation.name}
                />
                <CardContent>
                    <Typography variant="body2" color="text.secondary">
                    Detected {issue_type.name} in your {vegetation.name}.
                    </Typography>
                </CardContent>
            </Card>
            <Card sx={{my: 1, mb: 3}}>
                <CardHeader
                    title={issue_type.name}
                    subheader={issue_type.group}
                />
                <CardMedia
                    component="img"
                    image={issue_type.image}
                    alt={issue_type.name}
                />
                <CardContent>
                    <Typography variant="h6" color="text.primary">
                        Issue Description
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {issue_type.description}
                    </Typography>
                    <Typography variant="h6" color="text.primary">
                        Mitigation Techniques
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {issue_type.mitigation_techniques}
                    </Typography>
                </CardContent>
            </Card>
        </Container>
    </Page>)
}

DetectionDetailsPage.displayName = 'DetectionDetailsPage'
export default DetectionDetailsPage